import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Headimg2 from "../components/Headimg2";
import PdfViewer from "../components/PdfViewer";

const Resume = () => {
  return (
    <div>
      <Navbar />
      <Headimg2 heading="رزومه" text="مشاهده و دریافت فایل رزومه" />
      <PdfViewer fileName="resume.pdf" />
      <Footer />
    </div>
  );
};

export default Resume;
