import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Headimg2 from "../components/Headimg2";
import Posts from "../components/Posts";


const Blog = () => {
  return <div>
    <Navbar/>
    <Headimg2 heading="وبلاگ" text="این بخش در حال ساخت است (تقریبا تکمیله :)" />
    <Posts />
    <Footer />
  </div>
};

export default Blog;