import { useState, useEffect, useRef, useCallback } from "react";
import "./Posts.css";
import {
  BiSolidCommentDetail,
  BiSolidCalendarWeek,
  BiSolidCategoryAlt,
} from "react-icons/bi";

const Skeleton = () => (
  <div className="post skeleton">
    <div className="post-image skeleton-image"></div>
    <div className="post-content">
      <div className="skeleton-title"></div>
      <div className="skeleton-text"></div>
      <div className="skeleton-text small"></div>
      <div className="post-info">
        <div className="skeleton-info"></div>
        <div className="skeleton-info"></div>
        <div className="skeleton-info"></div>
      </div>
    </div>
  </div>
);

const Posts = () => {
  const [posts, setPosts] = useState([]);
  const [visibleCount, setVisibleCount] = useState(3);
  const [loading, setLoading] = useState(true);
  const loaderRef = useRef(null);
  const BASE_URL = "http://192.168.1.3:8000";

  useEffect(() => {
    fetch(`${BASE_URL}/api/blog/`)
      .then((res) => res.json())
      .then((data) => {
        setPosts(data);
        setLoading(false);
      })
      .catch((error) => console.error("Error fetching posts:", error));
  }, []);

  const loadMorePosts = useCallback(() => {
    if (visibleCount < posts.length) {
      setVisibleCount((prev) => prev + 2);
    }
  }, [visibleCount, posts.length]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMorePosts();
        }
      },
      { threshold: 1 }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [loadMorePosts]);

  return (
    <div className="posts-container">
      {loading ? (
        Array(3)
          .fill(0)
          .map((_, index) => <Skeleton key={index} />)
      ) : (
        <>
          {posts.slice(0, visibleCount).map((post) => (
            <div className="post" key={post.id}>
              {post.image ? (
                <img
                  className="post-image"
                  src={`${BASE_URL}${post.image}`}
                  alt={post.title}
                />
              ) : (
                <div className="post-image placeholder"></div>
              )}
              <div className="post-content">
                <h3>{post.title}</h3>
                <p>{post.content.substring(0, 150)}...</p>
                <div className="post-info">
                  <div className="info-details post-date">
                    <BiSolidCalendarWeek />
                    <span>
                      {new Date(post.created_at).toLocaleDateString("fa-IR")}
                    </span>
                  </div>
                  <div className="info-details category">
                    <BiSolidCategoryAlt />
                    <span>{post.category.name}</span>
                  </div>
                  <div className="info-details comments">
                    <BiSolidCommentDetail />
                    <span>
                      {post.comments.length === 0
                        ? "بدون کامنت"
                        : `${post.comments.length} کامنت`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {visibleCount % 2 !== 0 && visibleCount < posts.length && (
            <Skeleton />
          )}
        </>
      )}
      <div ref={loaderRef} style={{ height: "20px", width: "100%" }}></div>
    </div>
  );
};

export default Posts;
