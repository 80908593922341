import "./Headimg.css";
import React from 'react';
import headerImg from "../assets/headerimg.jpg";
import { Link } from "react-router-dom";
import { FaArrowDown } from "react-icons/fa";

const Headimg = () => {
  return (
    <div className="header">
      <div className="content">
        <h1>طراح سایت و مدیر سئو</h1>
        <p>با خلاقیت در طراحی و استراتژی در سئو <br /> بهترین‌ها رو برای <strong><u>کسب‌وکارت</u></strong> رقم بزن!</p>
        <div>
          <Link to="/contact" className="btn" >تماس و مشاوره‌</Link>
          <Link to="/resume" className="resumebtn">‌دانلود فایل رزومه</Link>
        </div>
      </div>
      <div className="bubble">
        <p >به زودی در این مکان تصویر نصب میشود</p>
      </div>
      <a aria-label="رفتن به بخش تخصص ها" href="#expertises" onClick={(e) => {
          e.preventDefault();
          document.getElementById("expertises")?.scrollIntoView({ behavior: "smooth" });
        }} className="svg">
        <FaArrowDown />
      </a>
    </div>
  )
}

export default Headimg
