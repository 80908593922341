import "./Footer.css";
import React from 'react';
import { Link } from "react-router-dom";
import { FaBars, FaLinkedin, FaPhoneSquare , FaTelegram, FaWhatsappSquare, FaEnvelope, FaGithub, FaLinkedinIn, FaPhone, FaTelegramPlane, FaWhatsapp } from "react-icons/fa";




const Footer = () => {
  return (
    <div className="footer">
        <div className="footer-container">
            <div className="right">
                <div className="texticon">
                    <FaPhone size={25} style={{ color:"#fff" }} />
                    <Link to="tel:+989333303837">شماره تماس: 09333303837</Link>
                </div>
                <div className="texticon">
                    <FaEnvelope size={25} style={{ color:"#fff" }} />
                    <Link to="mailto:admin@rojax.ir">ایمیل: admin@rojax.ir</Link>
                </div>
                <h4>صفحات مجازی:</h4>
                <div className="socials">
                    <a href="https://t.me/v2raykadeadmin" target="_blank" aria-label="تلگرام"><FaTelegram/></a>
                    <a href="https://wa.me/+98" target="_blank" aria-label="واتساپ"><FaWhatsappSquare/></a>
                    <a href="https://www.linkedin.com/" target="_blank" aria-label="لینکدین"><FaLinkedin/></a>
                </div>
            </div>
            <div className="left">
                <h4>درباره من:</h4>
                <p>لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است</p>

            </div>
        </div>
    </div>
  )
}

export default Footer
