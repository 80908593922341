import { Link, NavLink } from "react-router-dom";
import "./Navbar.css";
import React, { useState } from 'react';
import { FaBars, FaLinkedin, FaPhoneSquare, FaTelegram, FaTimes, FaWhatsappSquare } from "react-icons/fa";
import { HiMiniBars3BottomRight } from "react-icons/hi2";
import { LiaTimesSolid } from "react-icons/lia";

const Navbar = () => {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    const [color, setColor] = useState(false);
    const changeColor = () => {
        if (window.scrollY >= 100) {
            setColor(true);
        } else {
            setColor(false);
        }
    };

    window.addEventListener("scroll", changeColor);

    return (
        <div className={`${color ? "navbar navbar-bg" : "navbar"} ${click ? "menu-open" : ""}`}>
            <div className="headsocials">
                <a href="https://t.me/v2raykadeadmin" target="_blank" aria-label="تلگرام"><FaTelegram /></a>
                <a href="https://wa.me/+98" target="_blank" aria-label="واتساپ"><FaWhatsappSquare /></a>
                <a href="https://www.linkedin.com/" target="_blank" aria-label="لینکدین"><FaLinkedin /></a>
                <a href="tel:+989333303837" aria-label="تلفن تماس"><FaPhoneSquare /></a>
            </div>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
                <li><NavLink to="/" className={({ isActive }) => isActive ? "active" : ""}>صفحه‌اصلی</NavLink></li>
                <li><NavLink to="/services" className={({ isActive }) => isActive ? "active" : ""}>خدمات</NavLink></li>
                <li><NavLink to="/resume" className={({ isActive }) => isActive ? "active" : ""}>دانلود رزومه</NavLink></li>
                <li><NavLink to="/projects" className={({ isActive }) => isActive ? "active" : ""}>نمونه کارها</NavLink></li>
                <li><NavLink to="/blog" className={({ isActive }) => isActive ? "active" : ""}>وبلاگ</NavLink></li>
                <div>
                    <a href="tel:+989333303837" aria-label="تلفن تماس"><FaPhoneSquare /></a>
                    <a href="https://t.me/v2raykadeadmin" target="_blank" aria-label="تلگرام"><FaTelegram /></a>
                    <a href="https://wa.me/+98" target="_blank" aria-label="واتساپ"><FaWhatsappSquare /></a>
                    <a href="https://www.linkedin.com/" target="_blank" aria-label="لینکدین"><FaLinkedin /></a>
                </div>
            </ul>
            <div className="hamburger" onClick={handleClick}>
                {click ? (<LiaTimesSolid />) : (<HiMiniBars3BottomRight />)}
            </div>
            <Link to="/"><span>Rojax</span></Link>
        </div>
    );
};

export default Navbar;
