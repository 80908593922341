import "./Expertises.css";
import { Link } from "react-router-dom";
import React from "react";
import { FaMobileAlt, FaPenNib, FaCode } from "react-icons/fa";

const Skills = () => {
  return (
    <div className="expertises" id="expertises">
      <h2>تخصص‌های من</h2>
      <p>تخصص در حوزه‌های مختلف و تجربه کار روی پروژه‌های متنوع</p>
      <div className="container">
        <div>
          <FaMobileAlt />
          <h3>طراحی اپلیکیشن</h3>
          <p>
            ساخت اپلیکیشن‌های موبایل و وب اپلیکیشن‌های ریسپانسیو با بهترین
            عملکرد و تجربه کاربری.
          </p>
          <Link to="#">بیشتر بدانید</Link>
        </div>
        <div>
          <FaPenNib />
          <h3>طراحی UI/UX</h3>
          <p>
            ایجاد تجربه کاربری جذاب و طراحی رابط‌های کاربری مدرن و کاربرپسند که
            باعث افزایش تعامل کاربران می‌شه.
          </p>
          <Link to="#">بیشتر بدانید</Link>
        </div>
        <div>
          <FaCode />
          <h3>توسعه وب</h3>
          <p>
            طراحی و پیاده‌سازی وب‌سایت‌های حرفه‌ای با استفاده از تکنولوژی‌های
            روز. از طراحی رابط کاربری گرفته تا توسعه بک‌اند و بهینه‌سازی برای
            موتورهای جستجو.
          </p>
          <Link to="#">بیشتر بدانید</Link>
        </div>
      </div>
    </div>
  );
};

export default Skills;
