import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Headimg2 from "../components/Headimg2";


const Services = () => {
  return <div>
    <Navbar/>
    <Headimg2 heading="خدمات" text="این بخش در حال ساخت است" />
    <Footer />
  </div>
};

export default Services;