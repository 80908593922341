import React from "react";
import "./PdfViewer.css";

const PdfViewer = ({ fileName }) => {
  const fileUrl = `/files/${fileName}`;

  return (
    <div className="pdfviewer">
      <a
        href={fileUrl}
        download="resume.pdf"
        target="_blank"
        rel="noopener noreferrer"
        className="btn btncolor"
      >
        دانلود PDF رزومه
      </a>

      <iframe className="pdf" src={fileUrl} width="100%" height="550px" style={{ border: "none" }}>
        <p>مرورگر شما از آی فریم پشتیبانی نمیکند</p>
      </iframe>
    </div>
  );
};

export default PdfViewer;
