import React from "react";
import Navbar from "../components/Navbar";
import Headimg from "../components/Headimg";
import Footer from "../components/Footer";
import Skills from "../components/Skills";
import Expertises from "../components/Expertises";
import Aboutme from "../components/Aboutme";


const Home = () => {
  return (
    <div>
      <Navbar />
      <Headimg />
      <Expertises />
      {/* <Aboutme /> */}
      {/* <Skills /> */}
      <Footer />
    </div>
  );
};

export default Home;
